import { flexRender, Table } from '@tanstack/react-table';
import { TableCell, TableRow, TableFooter as MTableFooter } from '@mui/material';
import { cx } from '@linaria/core';
import { Fragment } from 'react';
import { Pagination } from './Pagination/Pagination';
import { getCellStyles } from './utils';
import { ITableProps } from './type';

interface Props<D extends Record<never, never>> extends Pick<ITableProps<D>, 'enablePagination'> {
  table: Table<D>;
  isMobileView: boolean;
}

const PaginatedFooter = <D extends Record<never, never>>({ table }: Pick<Props<D>, 'table'>) => {
  return (
    <TableRow>
      <TableCell colSpan={table.getVisibleLeafColumns().length}>
        <Pagination
          previousPage={table.previousPage}
          nextPage={table.nextPage}
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
          gotoPage={table.setPageIndex}
          pageOptions={table.getPageOptions()}
          pageIndex={table.getState().pagination.pageIndex}
        />
      </TableCell>
    </TableRow>
  );
};

const TableFooterCell = <D extends Record<never, never>>({ table, isMobileView }: Pick<Props<D>, 'table' | 'isMobileView'>) => {
  if (table.getCoreRowModel().rows.length === 0) {
    return null;
  }
  return (
    <Fragment key="footer-cell">
      {table.getFooterGroups().map((footerGroup) => (
        <TableRow key={footerGroup.id} className="table-header-row" role="rowgroup">
          {footerGroup.headers.map((header) => {
            const column = header?.column;
            return (
              <TableCell
                className={cx('table-cell', 'table-header-cell', column.columnDef.meta?.sticky && 'sticky')}
                style={getCellStyles<D>(column)}
                key={header.id}
                colSpan={header.colSpan}
                role="columnheader"
                data-testid="table-footer-cell"
                id="footer-cell"
                data-label={column.columnDef.header}
              >
                {isMobileView ? (
                  <>
                    <div className="headerCell">{flexRender(column.columnDef.header, { ...header.getContext(), isMobileView })}</div>
                    <div className="valueCell">{flexRender(column.columnDef.footer, { ...header.getContext(), isMobileView })}</div>
                  </>
                ) : header.isPlaceholder ? null : (
                  <div className="header-cell-container">{flexRender(header.column.columnDef.footer, header.getContext())}</div>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </Fragment>
  );
};

export const TableFooter = <D extends Record<never, never>>({ table, enablePagination, isMobileView }: Props<D>) => {
  const enableFooter =
    table
      .getFooterGroups()
      .map((group) => group.headers.map((header) => header.column.columnDef.footer))
      .flat()
      .filter(Boolean)?.length !== 0;

  return (
    <MTableFooter className="table-header">
      {enableFooter && <TableFooterCell table={table} isMobileView={isMobileView} />}
      {enablePagination && <PaginatedFooter table={table} />}
    </MTableFooter>
  );
};
