import { cx } from '@linaria/core';
import { IconControlCenterEmptyTable } from 'components/Icons';
import { noRecord } from './NoRecords.styles';

type Props = {
  noRecordsText?: string;
  hideRecordsText?: boolean;
  layoutType?: string;
};
export const NoRecords = ({ noRecordsText = 'No records found', layoutType, hideRecordsText }: Props): JSX.Element => {
  return (
    <div className={cx(noRecord)} data-testid="no-record-table" data-layout-type={layoutType}>
      <IconControlCenterEmptyTable size={200} />
      {!hideRecordsText && <span>{noRecordsText}</span>}
    </div>
  );
};
