import { useCurrentUserId, useIsOrgAccount } from 'modules/Auth/hooks';
import { ORGANIZATION_ROLE_ID_ADMIN, ORGANIZATION_ROLE_ID_MEMBER_MIN } from 'modules/Organizations/constants';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useOrgHasTeamRole } from 'modules/Organizations/hooks';

interface LinkInterface {
  text: string;
  url?: string;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface DataInterface {
  id: string;
  title?: string;
  links: LinkInterface[];
}

export function useAccountPopoverLinks(onLogout: React.MouseEventHandler<HTMLButtonElement>): DataInterface[] {
  const isOrg = useIsOrgAccount();
  const userId = useCurrentUserId();
  const org = useCurrentUserOrg();

  return isOrg ? organizationAccountLinks(userId, onLogout, org?.role.id) : personalAccountLinks(userId, onLogout);
}

function personalAccountLinks(userId: string, onLogout: React.MouseEventHandler<HTMLButtonElement>): DataInterface[] {
  const { nodePoolDeploymentsLDFF, controlCenter } = useFeatureFlagsLD();
  return [
    {
      id: '0',
      title: '',
      links: [
        {
          text: 'My Apps',
          url: `/${userId}`,
        },
        {
          text: 'Collaborations',
          url: `/${userId}/collaborations`,
        },
        ...(controlCenter
          ? [
              {
                text: 'Control Center',
                url: '/control-center',
              },
            ]
          : []),
      ],
    },
    {
      id: '1',
      title: 'Personal Settings',
      links: [
        {
          text: 'Account',
          url: '/settings',
        },
        ...(nodePoolDeploymentsLDFF
          ? [
              {
                text: 'Compute',
                url: '/settings/compute',
              },
            ]
          : []),
        {
          text: 'Billing',
          url: '/settings/billing',
        },
        {
          text: 'Security',
          url: '/settings/security',
        },
        {
          text: 'Usage',
          url: '/settings/usage',
        },
        {
          text: 'Sign Out',
          handleClick: onLogout,
        },
      ],
    },
  ];
}

function organizationAccountLinks(orgId: string, onLogout: React.MouseEventHandler<HTMLButtonElement>, roleId?: string): DataInterface[] {
  const isAdmin = roleId === ORGANIZATION_ROLE_ID_ADMIN;
  const isAdminOrMember = roleId !== ORGANIZATION_ROLE_ID_MEMBER_MIN;
  const orgHasTeamRole = useOrgHasTeamRole();
  const { nodePoolDeploymentsLDFF, controlCenter } = useFeatureFlagsLD();

  return [
    {
      id: '0',
      title: '',
      links: [
        {
          text: 'Org Apps',
          url: `/${orgId}`,
        },
        ...(controlCenter && isAdmin
          ? [
              {
                text: 'Control Center',
                url: '/control-center',
              },
            ]
          : []),
      ],
    },
    {
      id: '1',
      title: 'Organization Settings',
      links: [
        {
          text: 'Account',
          url: '/settings/organization',
        },
        ...(nodePoolDeploymentsLDFF
          ? [
              {
                text: 'Compute clusters',
                url: '/settings/compute',
              },
            ]
          : []),
        ...(isAdmin
          ? [
              {
                text: 'Security',
                url: '/settings/organization/security',
              },
            ]
          : []),
        ...(isAdminOrMember
          ? [
              {
                text: 'Members',
                url: '/settings/organization/members',
              },
            ]
          : []),
        ...(isAdminOrMember && orgHasTeamRole
          ? [
              {
                text: 'Teams',
                url: '/settings/organization/teams',
              },
            ]
          : []),
        {
          text: 'Sign Out',
          handleClick: onLogout,
        },
      ],
    },
  ];
}
