/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useMemo, useRef } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  TableState,
  getFacetedRowModel,
} from '@tanstack/react-table';
import { Table as MTable } from '@mui/material';
import { cx } from '@linaria/core';
import { useBreakpoint } from 'utils/responsive';
import { breakpoints } from 'styles/utils';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useMeasure } from 'react-use';
import { Column, ITableProps } from './type';
import { ScrollTrigger } from './ScrollTrigger';
import { mobileStyle, wrapper } from './Table.styles';
import { TableContent } from './TableContent';
import { TableHeader } from './TableHeader';
import { TableFooter } from './TableFooter';
import { MIN_ROW_HEIGHT_PX } from './utils';

const Table_ = <D extends Record<never, never>>({
  tableOptions,
  infinite,
  rowHeight = MIN_ROW_HEIGHT_PX,
  isInitializing,
  noRecordsText,
  hideRecordsText,
  useMobileView = true,
  enablePagination = false,
  layoutType,
  maxHeight = 750,
}: ITableProps<D>): JSX.Element => {
  const isMobileScreen = useBreakpoint(breakpoints.down('tab'));
  const isMobileViewEnable = isMobileScreen && useMobileView;
  const parentRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [measureRef, { height: measuredHeight }] = useMeasure();

  const nonInfiniteData = tableOptions?.data ?? {};
  const data = useMemo(() => (infinite ? infinite.pages.flat() : nonInfiniteData), [nonInfiniteData, infinite]);

  function transformColumnsV7toV8(columnsV7: Column<D>[] | any[]): Column<D>[] {
    return columnsV7.map((column) => ({
      header: column?.Header ?? column.header,
      accessorKey: column.accessorkey ?? column.accessor,
      size: column?.width ?? column?.size ?? 150,
      filterFn: column.filterFn ?? 'includesString',
      ...column,
    }));
  }

  const rawTable = useReactTable<D>({
    columns: transformColumnsV7toV8(tableOptions.columns),
    data,
    initialState: tableOptions.initialState as TableState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    ...(enablePagination && { getPaginationRowModel: getPaginationRowModel() }),
  });

  const table = useMemo(() => rawTable, [rawTable]);

  return (
    <ThemeProvider theme={theme}>
      <div ref={parentRef} className={cx(wrapper, mobileStyle)}>
        <div
          ref={measureRef as React.LegacyRef<HTMLDivElement>}
          className="table-container"
          style={{ height: measuredHeight >= maxHeight ? maxHeight : 'auto' }}
        >
          <MTable className={cx('table')} data-loading={isInitializing}>
            <TableHeader table={table} isMobileView={isMobileViewEnable} />
            <TableContent
              ref={parentRef as RefObject<HTMLDivElement>}
              table={table as any}
              noRecordsText={noRecordsText as string}
              hideRecordsText={hideRecordsText}
              infinite={infinite}
              isMobileView={isMobileViewEnable}
              isLoading={isInitializing}
              rowHeight={rowHeight}
              layoutType={layoutType}
            />
            <TableFooter<D> table={table} enablePagination={enablePagination} isMobileView={isMobileViewEnable} />
          </MTable>
        </div>
      </div>
    </ThemeProvider>
  );
};

Table_.defaultProps = {
  plugins: [],
};

export const Table_testable = { ScrollTrigger };

export const Table = Table_;
